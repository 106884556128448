export const OneShellHomeObj = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lighttextDesc: true,
    topLine: '',
    headLine: "India's first curated Billing, Inventory and Accounting Management SAAS Platform with simple and easy to use interface",
    subtitle: 'Custom features for every category!',
    description: '',
    buttonLabel: 'Download Now',
    imgStart: false,
    img: require('../../images/image_1.png'),
    alt: '',
    dark: true,
    primary: true,
    darkText: false   
}


