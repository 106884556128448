import styled from 'styled-components';


export const InfoContainer = styled.div`
    color: #fff;
    
    /* background: ${({ lightBg }) => 
    (lightBg ? '#f9f9f9' : '#010606')}; */
   

    @media screen and (max-width: 768px) {
        padding: 100px 0;
        
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    margin-top: 30px;
    z-index: 1;
    height: 200px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

   
    
`;

export const InfoRow = styled.div`
    display: grid;
    height: 200px;
    
    grid-auto-columns: minmax(auto, 1fr);
    align-items: left;
    

    grid-template-areas: ${`'col1 col2 col3'` };
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${`'col1' 'col2' 'col3'`};
        height: 400px;
    }
`;

export const InfoRow2 = styled.div`
    display: grid;
    height: 200px;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: left;

    grid-template-areas: ${`'col4 col5 col6'` };
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${`'col4' 'col5' 'col6'`};
        height: 400px;
    }
`;

export const InfoRow3 = styled.div`
    display: grid;
    height: 200px;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: left;

    grid-template-areas: ${`'col7 col8 col9'` };
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${`'col7' 'col8' 'col9'`};
        height: 400px;
    }
`;

export const InfoRow4 = styled.div`
    display: grid;
    height: 200px;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: left;

    grid-template-areas: ${`'col10 col11 col12'` };
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${`'col10' 'col11' 'col12'`};
        height: 400px;
    }
`;

export const HeadingWrap = styled.div`
    height: 75px;
    text-align: center;
    
`

export const Heading = styled.h1`
    color: #000;
    padding-top: 30px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 550;
   
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const ImgWrapS = styled.div`
    max-width: 250px;
    height: 60%;
    text-align: left;
    
`;

export const ImgS = styled.img`
    width: 25%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;

export const Subtitle = styled.p`
    max-width: 440px;  
    font-size: 22px;
    line-height: 25px;
    color: #000;
    font-weight: 600;
`;

export const SubtitleBeta = styled.sup`  
    font-size: 12px;
    color: #ef5350;
    font-weight: 600;
`;

export const SubtitleS = styled.p`
    max-width: 440px;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    font-weight: 500;
`;

export const Column1 = styled.div`
    margin-top: 0px;
    padding: 0 30px;
    grid-area: col1;

    @media screen and (max-width: 768px) {
        margin-top: -85px;
    }
`;

export const Column2 = styled.div`
    padding: 0 30px;
    grid-area: col2;

    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

export const Column3 = styled.div`
    
    padding: 0 30px;
    grid-area: col3;

    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

export const Column4 = styled.div`
    
    padding: 0 30px;
    grid-area: col4;

    @media screen and (max-width: 768px) {
        margin-top: -25px;
    }
`;

export const Column5 = styled.div`
    padding: 0 30px;
    grid-area: col5;

    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

export const Column6 = styled.div`
    
    padding: 0 30px;
    grid-area: col6;

    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

export const Column7 = styled.div`
    
    padding: 0 30px;
    grid-area: col7;

    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

export const Column8 = styled.div`
    padding: 0 30px;
    grid-area: col8;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

export const Column9 = styled.div`
    
    padding: 0 30px;
    grid-area: col9;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

export const Column10 = styled.div`
    
    padding: 0 30px;
    grid-area: col10;

    @media screen and (max-width: 768px) {
        margin-top: 70px;
    }
`;

export const Column11 = styled.div`
    padding: 0 30px;
    grid-area: col11;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

export const Column12 = styled.div`
    
    padding: 0 30px;
    grid-area: col12;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;