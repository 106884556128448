export const POSMobileBillingObj = {
    id: 'about2',
    lightBg: true,
    lightText: false,
    lighttextDesc: false,
    topLine: '',
    headLine: "POS with Mobile Billing (Offline and Online)",
    subtitle: 'Seamless and Cost effective solution for In-Store and Onine Billing with customised and easy user interface.',
    description: '',
    buttonLabel: 'Download Now',
    imgStart: true,
    img: require('../../images/image_2.png'),
    alt: '',
    dark: false,
    primary: false,
    darkText: true   
}
