export const OurPartnersData = [
    {
        image: require('../../images/egg_man.png')
    },
    {
        image: require('../../images/fish.png')
    },
    {
        image: require('../../images/goli.png')
    },
    {
        image: require('../../images/guru.png')
    },
    {
        image: require('../../images/jas.png')
    },
    {
        image: require('../../images/lassi.png')
    },
    {
        image: require('../../images/ranga.png')
    },
    {
        image: require('../../images/sri.png')
    },{
        image: require('../../images/svr.png')
    },
    {
        image: require('../../images/murthy.png')
    }
]