import React from 'react';
import Slider from '../Slider';

import { InfoContainer, InfoWrapper, InfoRow, 
    Heading, HeadingWrap } from './PartnersSayElements';
import { PartnersSayData } from './PartnersSayData';

const PartnerSay = () => {
  return (
    <>
        <HeadingWrap>
            <Heading>What our Clients Say!</Heading>
        </HeadingWrap>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow>
                   <Slider  slides={PartnersSayData} imgSize='L' />
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
      
    </>
  )
}

export default PartnerSay