import React,{useState} from 'react';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer';
import DeleteInfo from '../../components/DeleteInfo';
import OurPartners from '../../components/OurPartners';

const DeleteRequest = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <DeleteInfo/>
      {/* <Footer /> */}

     
    </>
  )
}

export default DeleteRequest
