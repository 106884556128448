import styled from 'styled-components';
import {Link as LinkR } from 'react-router-dom';
import {Link as LinkS } from 'react-scroll';
 
export const Nav = styled.nav`
    background: ${({ scrollNav }) => (scrollNav ? '#fff' : 'transparaent')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    box-shadow: ${({ scrollNav }) => (scrollNav ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' : '0px 0px')};
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 0px;
    max-width: 1100px;
`;

export const NavLogo = styled(LinkS)`
    color: #0d0d0d;
    justify-self: flex-start;
    
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 0px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        margin-left: 24px;
        
`;

export const MobileIcon = styled.div`
    display: None;

    @media screen and (max-width: 760px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    

    @media screen and (max-width: 768px) {
        display: none;
    }

    
`;

export const NavItem = styled.li`
    height: 80px;
    
`;

export const NavLinks = styled(LinkS)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1.5rem;
    height: 100%;
    cursor: pointer;

    &.active {
        /* border-bottom: 3px solid #ef5350; */
        color: #ef5350;
    }

    &:hover {
        transition: all 0.2s ease-in-out;
        color: #ef5350;
    }
    
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavBtnLink = styled(LinkR)`
    /* border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none; */
    
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1.5rem;
    height: 100%;
    cursor: pointer;


    &:hover {
        transition: all 0.2s ease-in-out;
        
        color: #ef5350;
    }
`;

/* export const ImgWrap = styled.div`
    max-width: 50px;
    height: 25%;
`; */

export const Img = styled.img`
    height: 30px;
    padding-right: 10px;
`;