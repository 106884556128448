import React from "react";
import { FaWindows } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinkContainer,
  FooterLinksWraper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterAddress,
  FooterInputName,
  FooterSelectName,
  WindowsIconDiv,
  HeadingWrap,
  Heading,
  ButtonFoot,
} from "./DeleteInfoElements";
import { BtnWrap } from "../OneShellHome/OneShellHomeElements";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const DeleteInfo = () => {
  const [message, setMessage] = React.useState("");
  const [name, setName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [type, setType] = React.useState("");

  const API_SERVER = "https://apiqa.oneshell.in";

  const getPosLink = async () => {
    await axios
      .get(`${API_SERVER}/v1/pos/customer/getPosLink`)
      .then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const submitQuery = async () => {
    if (name.length === 0) {
      toast.error("Please provide your name!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if(phoneNumber != '' || emailId != ''){
      if (phoneNumber.length !== 10 && phoneNumber != '') {
        toast.error(
          "Phone Number provided is Invalid. Please submit with correct Phone Number!",
          {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
  
      if (!isValidEmail(emailId) && emailId != '') {
        toast.error(
          "Email Id provided is Invalid. Please submit with correct Email Id!",
          {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    }else{
      toast.error(
        "Either Email Id or Phone Number Required!",
        {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    // console.log("type",type);
    if (type == '') {
      toast.error(
        "Type Required!",
        {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }

    

    await axios
      .post(`${API_SERVER}/pos/v1/contactUs/save`, {
        phoneNumber: phoneNumber,
        name: name,
        emailId: emailId,
        type: type,
        comments: message ? message : "No Message",
      })
      .then((response) => {
        if (response.data.success) {
          setName("");
          setPhoneNumber("");
          setEmailId("");
          setMessage("");
          setType("");

          toast.info(
            "Your Deleted Request has Submitted successfully.",
            {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return (
    <>
      <HeadingWrap style={{ marginTop: '5%' }}>
        <Heading>Request For Deletion</Heading>
      </HeadingWrap>
      <FooterContainer style={{ marginBottom: '5%' }}>
        <FooterLinkItems>
          <FooterLinkTitle>Lets Connect</FooterLinkTitle>

          <FooterInputName
            value={name}
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
          <FooterInputName
            type="number"
            value={phoneNumber}
            placeholder="Phone Number"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <FooterInputName
            value={emailId}
            placeholder="Email Id"
            onChange={(e) => setEmailId(e.target.value)}
          />
          <FooterInputName
            value={message}
            placeholder="Comment (Optional)"
            onChange={(e) => setMessage(e.target.value)}
          />
          <FooterSelectName onChange={(e) => setType(e.target.value)} value={type}>
              <option value="">Choose Type</option>
              <option value="Personal Information">Personal Information</option>
              <option value="Business Information">Business Information</option>
              <option value="Personal & Business Information">Personal & Business Information</option>
          </FooterSelectName>
          <BtnWrap style={{margin:'auto'}}>
            <ButtonFoot
              
              onClick={(e) => {
                submitQuery();
              }}
            >
              Submit
            </ButtonFoot>
          </BtnWrap>
        </FooterLinkItems>
      </FooterContainer>
    </>
  );
};

export default DeleteInfo;
