import React from 'react';
import { InfoContainer, InfoWrapper, InfoRow, 
    SubtitleS, ImgS, ImgWrapS, Column1, Column2, 
    Column3, Column4, Heading, HeadingWrap  } from './AppfeatureHomeElements';

const AppfeatureHome = () => {
  return (
    <>
        <HeadingWrap>
            <Heading>Why OneShell?</Heading>
        </HeadingWrap>
        <InfoContainer>
            <InfoWrapper>
                
                <InfoRow>
                    <Column1>
                        <ImgWrapS>
                            <ImgS src={require('../../images/user_management.png')}/>
                            <SubtitleS>Multi-User Management</SubtitleS> 
                        </ImgWrapS>
                    </Column1>
                    <Column2>
                        <ImgWrapS>
                            <ImgS src={require('../../images/device_instalations.png')}/>
                            <SubtitleS>Multi-Device Installations</SubtitleS>
                            
                        </ImgWrapS> 
                    </Column2>
                    <Column3>
                        <ImgWrapS>
                            <ImgS src={require('../../images/employee.png')}/>
                            <SubtitleS>Employee Progress Tracking</SubtitleS>
                            
                        </ImgWrapS> 
                    </Column3>
                    <Column4>
                        <ImgWrapS>
                            <ImgS src={require('../../images/credit.png')}/>
                            <SubtitleS>Advance Credit Management</SubtitleS>
                        </ImgWrapS> 
                    </Column4>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
            
      
    </>
  )
}

export default AppfeatureHome