import React from 'react';
import { Column10, Column11, Column12, Column7, Column8, Column9, InfoRow3, InfoRow4, Subtitle } from './BillingFeaturesElements';
import { InfoContainer, InfoWrapper, InfoRow, SubtitleS, 
ImgS, ImgWrapS, Column1, Column2, Column3, Heading, HeadingWrap, InfoRow2, Column5, Column6, Column4  } from './BillingFeaturesElements';

const BillingFeatures = () => {
  return (
    <>
        <HeadingWrap>
            <Heading>Our Quick Billing Features</Heading>
        </HeadingWrap>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow>
                    <Column1>
                        <ImgWrapS>
                            <ImgS src={require('../../images/quickinvoicing.png')}/>
                            <Subtitle>Quick Invoicing</Subtitle> 
                            <SubtitleS>Invoice and share sales transactions with your customer easily</SubtitleS>
                        </ImgWrapS>
                    </Column1>
                    <Column2>
                        <ImgWrapS>
                            <ImgS src={require('../../images/returns.png')}/>
                            <Subtitle>Returns</Subtitle>
                            <SubtitleS>Make sales return and purchase return entries and manage inventory effectively</SubtitleS>
                        </ImgWrapS> 
                    </Column2>
                    <Column3>
                        <ImgWrapS>
                            <ImgS src={require('../../images/quotation.png')}/>
                            <Subtitle>Quotation</Subtitle>
                            <SubtitleS>Create quotations and convert it to sale easily</SubtitleS>
                        </ImgWrapS> 
                    </Column3>
                </InfoRow>
                
            </InfoWrapper>
            
        </InfoContainer>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow2>
                    <Column4>
                        <ImgWrapS>
                            <ImgS src={require('../../images/purchase_order.png')}/>
                            <Subtitle>Purchase Order</Subtitle>
                            <SubtitleS>Create Purchase Orders, share with your vendors</SubtitleS>
                        </ImgWrapS>
                    </Column4>
                    <Column5>
                        <ImgWrapS>
                            <ImgS src={require('../../images/credit_man.png')}/>
                            <Subtitle>Credit Management</Subtitle>
                            <SubtitleS>Know your accounts receivable and accounts payable on a tap with our reports</SubtitleS>
                        </ImgWrapS> 
                    </Column5>
                    <Column6>
                        <ImgWrapS>
                            <ImgS src={require('../../images/delivery.png')}/>
                            <Subtitle>Delivery Chalan</Subtitle>
                            <SubtitleS>Create Delivery Chalan for all your consignments and convert it to sale</SubtitleS>
                        </ImgWrapS> 
                    </Column6>
                    
                </InfoRow2>
            </InfoWrapper>
        </InfoContainer>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow3>
                    <Column7>
                        <ImgWrapS>
                            <ImgS src={require('../../images/stock_management.png')}/>
                            <Subtitle>Stock Management</Subtitle>
                            <SubtitleS>Manage your stock online and offline seamlessly</SubtitleS>
                        </ImgWrapS>
                    </Column7>
                    <Column8>
                        <ImgWrapS>
                            <ImgS src={require('../../images/report.png')}/>
                            <Subtitle>Account Reports</Subtitle>
                            <SubtitleS>View your account reports and plan your business strategies</SubtitleS>
                        </ImgWrapS> 
                    </Column8>
                    <Column9>
                        <ImgWrapS>
                            <ImgS src={require('../../images/cloud.png')}/>
                            <Subtitle>Cloud Sync</Subtitle>
                            <SubtitleS>Data sync across devices and outlets, Both Online {'&'} Offline versions are available.</SubtitleS>
                        </ImgWrapS> 
                    </Column9>
                    
                </InfoRow3>
            </InfoWrapper>
        </InfoContainer>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow4>
                    <Column10>
                        <ImgWrapS>
                            <ImgS src={require('../../images/export_to_tally.png')}/>
                            <Subtitle>Export To Tally</Subtitle>
                            <SubtitleS>Export transactions to tally seamlessly</SubtitleS>
                        </ImgWrapS>
                    </Column10>
                    <Column11>
                        <ImgWrapS>
                            <ImgS src={require('../../images/e_way_web.png')}/>
                            <Subtitle>E-Way Bill {' '}</Subtitle>
                            <SubtitleS>Trigger E-Way generation from sale</SubtitleS>
                        </ImgWrapS> 
                    </Column11>
                    <Column12>
                        <ImgWrapS>
                            <ImgS src={require('../../images/e_invoice.png')}/>
                            <Subtitle>E-Invoice {' '}</Subtitle>
                            <SubtitleS>Generate E-Invoice effortlessly on a click</SubtitleS>
                        </ImgWrapS> 
                    </Column12>
                    
                </InfoRow4>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default BillingFeatures