import styled from 'styled-components';


export const InfoContainer = styled.div`
    color: #fff;
    background: #F3F3F2;
    /* background: ${({ lightBg }) => 
    (lightBg ? '#f9f9f9' : '#010606')}; */

    @media screen and (max-width: 768px) {
        padding: 100px 0;
        height: 600px;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    
    z-index: 1;
    height: 250px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const InfoRow = styled.div`
    display: grid;
    height: 200px;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    /* background: #F1F1F1; */
    grid-template-areas: ${`'col1 col2 col3'` };
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${`'col1' 'col2' 'col3'`};
    }
`;

export const HeadingWrap = styled.div`
    height: 100px;
    text-align: center;
    padding-top: 30px;
    background: #F3F3F2;

    @media screen and (max-width: 768px) {
        margin-top: 275px;
    }
`

export const Heading = styled.h1`
    color: #000;
    padding-right: 10px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 550;
   
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const CardWrap = styled.div`
    background: #fff;
    max-width: 250px;
    border-radius: 20px;
    border: 2px solid;
    padding: 20px;
    width: 220px;
    height: 140px;
    text-align: center;

`;

export const AmountGstWrap = styled.div`
    display: flex;
`;

export const Subtitle = styled.p`
    max-width: 440px;  
    font-size: 26px;
    line-height: 25px;
    color: #ef5350;
    font-weight: 600;
    text-align:left;
`;

export const SubtitleS = styled.p`
    max-width: 440px;  
    font-size: 26px;
    line-height: 75px;
    color: #000;
    font-weight: bold;
    
`;

export const PlusGst = styled.p`
    font-size: 16px;
    color: #ef5350;
    font-weight: 600;
    align-self: center;
    width: 30%;
`;

export const Column1 = styled.div`
    margin-top: 0px;
    padding: 0 10px;
    grid-area: col1;
    width: 300px;

    @media screen and (max-width: 768px) {
        margin-top: -75px;
    }
`;

export const Column2 = styled.div`
    padding: 0 10px;
    grid-area: col2;
    width: 300px;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }
`;

export const Column3 = styled.div`
    
    padding: 0 10px;
    grid-area: col3;
    width: 300px;

    @media screen and (max-width: 768px) {
        margin-top: 50px;
    }
`;
