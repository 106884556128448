import React from "react";
import { FaWindows } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinkContainer,
  FooterLinksWraper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterAddress,
  FooterInputName,
  WindowsIconDiv,
  HeadingWrap,
  Heading,
  ButtonFoot,
} from "./FooterElements";
import { BtnWrap } from "../OneShellHome/OneShellHomeElements";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


toast.configure();

const Footer = () => {
  const [message, setMessage] = React.useState("");
  const [name, setName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [emailId, setEmailId] = React.useState("");

  const API_SERVER = "https://apiqa.oneshell.in";

  const getPosLink = async () => {
    await axios
      .get(`${API_SERVER}/v1/pos/customer/getPosLink`)
      .then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const submitQuery = async () => {
    if (name.length === 0) {
      toast.error("Please provide your name!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (phoneNumber.length !== 10) {
      toast.error(
        "Phone Number provided is Invalid. Please submit with correct Phone Number!",
        {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }

    if (!isValidEmail(emailId)) {
      toast.error(
        "Email Id provided is Invalid. Please submit with correct Email Id!",
        {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }

    await axios
      .post(`${API_SERVER}/pos/v1/contactUs/save`, {
        phoneNumber: phoneNumber,
        name: name,
        emailId: emailId,
        comments: message ? message : "No Message",
      })
      .then((response) => {
        if (response.data.success) {
          setName("");
          setPhoneNumber("");
          setEmailId("");
          setMessage("");

          toast.info(
            "Thank you for showing interest in our software. Our team will reach you out shortly",
            {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return (
    <>
      <HeadingWrap>
        <Heading>Onboard OneShell and do more than just billing</Heading>
      </HeadingWrap>
      <FooterContainer>
        <FooterWrap>
          <FooterLinkContainer>
            <FooterLinksWraper>
              <FooterLinkItems>
                <FooterLinkTitle>Quick Links</FooterLinkTitle>
                <FooterLink target="_blank" to={"//pos.oneshell.in/#/login"}>
                  POS Login
                </FooterLink>
                <FooterLink target="_blank" to={"//oneshell.in/"}>
                  Online
                </FooterLink>
                <FooterLink target="_blank" to={"//pos.oneshell.in/#/faq"}>
                  Faq
                </FooterLink>
                <FooterLink
                  target="_blank"
                  to={
                    "//www.youtube.com/playlist?list=PLxnpmUozNLw1ivTN6it-KibMlePcGveKI"
                  }
                >
                  Tutorials
                </FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Contact us</FooterLinkTitle>
               {/* <FooterAddress>
                  #143, 11th 'B' Cross, 2nd Stage, West of Chord Road,
                  Mahalakshmipuram, Bengaluru, Karnataka 560086
                </FooterAddress> */}

                <FooterAddress>+91 88846 76777</FooterAddress>
                <FooterAddress>+91 93538 23910</FooterAddress>
              </FooterLinkItems>
            </FooterLinksWraper>
            <FooterLinksWraper>
              <FooterLinkItems>
                <FooterLinkTitle>Lets Connect</FooterLinkTitle>

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "5px" }}>
                    <FooterInputName
                      value={name}
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div>
                    <FooterInputName
                      type="number"
                      value={phoneNumber}
                      placeholder="Phone Number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
                <FooterInputName
                  value={emailId}
                  placeholder="Email Id"
                  style={{ width: "205px" }}
                  onChange={(e) => setEmailId(e.target.value)}
                />
                <FooterInputName
                  value={message}
                  placeholder="Comment (Optional)"
                  style={{ width: "205px" }}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <BtnWrap>
                  <ButtonFoot
                    onClick={(e) => {
                      submitQuery();
                    }}
                  >
                    Submit
                  </ButtonFoot>
                </BtnWrap>
              </FooterLinkItems>
              <FooterLinkItems style={{ alignItems: "end" }}>
                <Link
                  target={"_blank"}
                  to={
                    "//play.google.com/store/apps/details?id=com.oneshellpartner"
                  }
                >
                  <img
                    style={{ width: "153px", marginRight: "-10px" }}
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </Link>
                <WindowsIconDiv to={"/"} onClick={getPosLink}>
                  <FaWindows style={{ color: "#000", marginRight: "5px" }} />
                  <p style={{ fontSize: "10px", color: "#000" }}>
                    Download <br />
                    Windows Installer
                  </p>
                </WindowsIconDiv>
                {/* <Button onClick={getPosLink} style={{ color: "white" }}>
                  Download for Desktop
                </Button> */}
              </FooterLinkItems>
            </FooterLinksWraper>
          </FooterLinkContainer>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
