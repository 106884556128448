import React from 'react';
import { InfoContainer, InfoWrapper, InfoRow, Column2, Column1, TextWrapper, 
    Heading, Subtitle, Img, ImgWrap } from './OnlineBusPageElements';
import {BsGlobe } from 'react-icons/bs';
import {IoLogoGooglePlaystore } from "react-icons/io5";
import { FaAppStore} from "react-icons/fa";
import { Link } from 'react-router-dom';

const OnlineBusPage = ({lightBg, id, imgStart, topLine, lightText, headLine, darkText, description, buttonLabel, img, alt, subtitle}) => {
  
  
  
    
    return (
    <>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <Heading>{headLine}</Heading>
                            <Subtitle>{subtitle}</Subtitle>
                            <Subtitle> Available on IOS, Android and Web</Subtitle>
                            <Link target={'_blank'} to={"//apps.apple.com/in/app/oneshell/id1525255805"}>
                                <FaAppStore style={{width:'25px', height:'25px', color:'#ef5350'}}/>
                            </Link>
                            ' '
                            <Link target={'_blank'} to={"//play.google.com/store/apps/details?id=com.oneshell"}>
                                <IoLogoGooglePlaystore style={{width:'25px', height:'25px', color:'#ef5350'}} />
                            </Link>
                            ' '
                            <Link target={'_blank'} to={"//oneshell.in"}>
                                <BsGlobe style={{width:'25px', height:'25px', color:'#ef5350'}}/>
                            </Link>
                        </TextWrapper>
                        
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt} />
                        </ImgWrap>
                    </Column2>
                </InfoRow>
                
            </InfoWrapper>
        </InfoContainer>
            
      
    </>
  )
}

export default OnlineBusPage