import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column2,
  Column1,
  TextWrapper,
  Heading,
  Subtitle,
  BtnWrap,
  Img,
  ImgWrap,
  ButtonDownload
} from "./OneShellHomeElements";
import axios from "axios";

const OneShellHome = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headLine,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  subtitle,
}) => {
  const API_SERVER = "https://api.oneshell.in";

  const getPosLink = async () => {
    await axios
      .get(`${API_SERVER}/v1/pos/customer/getPosLink`)
      .then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return (
    <>
      <InfoContainer id="home">
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Heading>{headLine}</Heading>
                <Subtitle>{subtitle}</Subtitle>
                <BtnWrap>
                      <ButtonDownload onClick={getPosLink} >{buttonLabel}</ButtonDownload>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default OneShellHome;
