/* import { format } from 'path'; */
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import { Helmet } from "react-helmet";
import Home from './pages';
import DeleteRequest from './pages/DeleteRequest';


function App() {
  return (
    <div>
      <Helmet>
          <html lang="en" />
          <title>OneShell Home</title>
          <meta name="description" content="OneShell POS, India's first curated Billing, Inventory and Accounting Management SAAS Platform with simple and easy to use interface" />
          <meta name="keywords" content="Invoice software, 
            Online billing system, Accounting software, Online invoicing,
            Invoice management, Billing management, Financial management software,
            SaaS accounting, Cloud accounting, Online accounting, Invoice generator,
            Subscription billing, Expense management, Payment processing,
            Bookkeeping software, Online invoicing tool, SaaS billing platform, 
            Small business accounting software, Cloud-based billing software for small businesses,
            Web-based invoicing software, Cloud-based accounting software,
            Retail Billing Software, Restaurant POS, Restaurant Point of sales system, 
            Restaurant billing software, Mandi POS, Mandi billing software, 
            Grocery billing software, Grocery POS, Grocery point of sales system, 
            Wholesale billing software, Wholesale POS, Wholesale point of sales system, 
            Distributors billing software, Distributors POS, Distributors point of sales system,
            Jewellery billing software, Jewellery POS, Jewellery point of sales system, 
            Stationary billing software, Stationary POS, Stationary point of sales system, 
            Bakery billing software, Bakery POS, Bakery point of sales system, 
            Fast food billing software, Fast food POS, Fast food point of sales system,
            General Stores billing software, General Stores POS, General Stores point of sales system,
            Billing software for SMB, Billing software for SME, Manufacturer billing software,
            Manufacturer POS, Manufacturer point of sales system, Quick billing, 
            Quick invoicing, Best billing software, Cheap billing software,
            Good billing software, Best cloud based billing software, Cheap cloud based billing software,
            Good cloud based billing software, Credit management software,
            E Way, E Invoice, Cloud Sync software, Inventory management, Stock Management,
            Mobile Billing software, Mobile Accounting software, Desktop and mobile billing software, 
            Cloud ERP, GST, Temple billing software, Temple POS, Temple point of sales system, 
            Computer billing software, Computer POS, Computer point of sales system, 
            Hardware stores billing software, Hardware stores POS, Hardware stores point of sales system,
            Clothing stores billing software, Clothing stores POS, Clothing stores point of sales system,
            Service billing software, Service POS, Service point of sales system, 
            GSTR1 software, Point of sale system, Multi User billing software, 
            Multi Device billing software, Retail POS system, Shop management software, 
            Retail billing software, Point of sale system, Invoice software for small businesses,
            Online billing system" />
      </Helmet>
      <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/deleteRequest' element={<DeleteRequest />} />
          </Routes>
      </Router>
      
       {/* <Router>
        <Navbar />
        <Routes>
            <Route exact path='/' />
        </Routes>
      </Router>  */}
    </div>
  );
}

export default App;
