import styled from 'styled-components';
import { FaAppStore} from "react-icons/fa";
import { AiOutlineWindows } from "react-icons/ai";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { BsGlobe } from "react-icons/bs";

export const InfoContainer = styled.div`
    color: #fff;
    /* background: ${({ lightBg }) => 
    (lightBg ? '#f9f9f9' : '#010606')}; */
    
    margin-top: -100px;

    @media screen and (max-width: 768px) {
        padding: 100px 0;
        
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 375px;
    width: 100%;
    mad-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    margin-bottom: 100px;
`;

export const InfoRow = styled.div`
    display: grid;
    height: 375px;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => imgStart ? 
    `'col2 col1'` : `'col1 col2'` };
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => imgStart ? 
        `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
        margin-top: -20px;
    }
`;

export const Column1 = styled.div`
    margin-top: 150px;
    padding: 0 30px;
    grid-area: col1;

    @media screen and (max-width: 768px) {
        margin-top: 100px;
    }
`;

export const Column2 = styled.div`
    margin-top: 150px;
    padding: 0 0;
    grid-area: col2;
    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #000;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    color: #000;
    margin-bottom: 22px;
    padding-right: 10px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 550;
    /* color: ${({ lightText }) => ( lightText ? '#f7f8fa' : '#010606') }; */

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    /* color: ${({darkText}) => (darkText ? '#010606' : '#000')}; */
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;

export const ImgWrapS = styled.div`
    max-width: 135px;
    height: 50%;
    text-align: center;
`;

export const ImgS = styled.img`
    width: 50%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;

export const SubtitleS = styled.p`
    max-width: 440px;  
    font-size: 20px;
    line-height: 24px;
    color: #000;
    
`;

export const iWindows = styled(AiOutlineWindows)`
    margin-left: 8px;
    font-size: 20px;
`
export const iGPlayStore = styled(IoLogoGooglePlaystore)`
    margin-left: 8px;
    font-size: 20px;
`
export const iGlobe = styled(BsGlobe)`
    margin-left: 8px;
    font-size: 20px;
`
export const iAppStore = styled(FaAppStore)`
    margin-left: 8px;
    font-size: 20px;
`