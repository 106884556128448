export const PartnersSayData = [
   // {
   //     image: require('../../images/eggman_traders.png')
   // },
    {
        image: require('../../images/fish_world.png')
    },
    {
        image: require('../../images/goli_vada_pav.png')
    }
];



