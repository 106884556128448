import "./Slider2.css";
import { useState } from "react";
import Slider from "react-slick";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

const Slider2 = ( { slides } ) => {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <MdKeyboardArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <MdKeyboardArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 4,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div className="slider2">
      <Slider {...settings}>
        
        {slides.map((img, idx) => (
          <div className={idx === imageIndex ? "slide2 activeSlide" : "slide2"}
            key={idx}>
            <img src={img.image} alt={img} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Slider2;