import styled from "styled-components";
/* import { Link } from "react-scroll"; */
import { Link as LinkR } from "react-router-dom";

export const FooterContainer = styled.footer`
    background-color: #101522;
    height: 250px;
    @media screen and (max-width: 820px) {
        margin-top: 20px;
        height: 350px;
    }
`

export const FooterWrap = styled.div`
    padding: 48px 24px;
    height: 250px;
    display: flex;
    flex-driection: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    
`
export const FooterLinkContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px) {
        padding-top: 100px;
    }
`

export const FooterLinksWraper = styled.div`
    display: flex;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 200px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
        
    }
`

export const FooterLinkTitle = styled.div`
    font-size: 18px;
    margin-bottom: 16px;
`

export const FooterLink = styled(LinkR)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        color: #ef5350;
        transition: 0.3s ease-out;
    }
`

export const FooterAddress = styled.p`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 12px;
`
export const FooterInputName = styled.input`
    height: 27px;
    width: 100px;
    font-size: 11px;
    border-radius: 7px;
    padding-left: 10px;
    margin-bottom: 7px;
`

export const WindowsIconDiv = styled.div`
    margin-left: 9px;
    border-radius: 7px;
    background: #fff;
    
    white-space: nowrap;
    padding: 8px 15px;
    font-size: 20px;
    ouline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#ff0000' : '#fff') };
    }
`
export const HeadingWrap = styled.div`
    height: 75px;
    text-align: center;
    padding-top: 20px;
    
    @media screen and (max-width: 768px) {
       
}
`

export const Heading = styled.h1`
    color: #000;
    padding-right: 10px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 550;
   
    @media screen and (max-width: 768px) {
        font-size: 26px;

    }
`;

export const ButtonFoot = styled.button`
    border-radius: 5px;
    background: #ef5350;
    white-space: nowrap;
    padding: 5px 20px;
    font-size: 12px;
    ouline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-top: 3px;
    color: #fff;

    &:hover{
        transition: all 0.2s ease-in-out;
        color: #000; 
    }
`;
