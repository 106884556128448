import React,{useState} from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar/Navbar';

import OneShellHome from '../components/OneShellHome';
import { OneShellHomeObj } from '../components/OneShellHome/OneShellHomeData';

import AppfeatureHome from '../components/AppfeatureHome';


import POSMobileBilling from '../components/POSMobileBilling';
import { POSMobileBillingObj } from '../components/POSMobileBilling/POSMobileBillingData';

import OnlineBusPage from '../components/OnlineBusPage';
import { OnlineBusPageObj } from '../components/OnlineBusPage/OnlineBusPageData';
import Footer from '../components/Footer';
import SuitForAll from '../components/SuitForAll';
import BillingFeatures from '../components/BillingFeatures';
import AnnualPlans from '../components/AnnualPlans';
import OurPartners from '../components/OurPartners';
import PartnerSay from '../components/PartnersSay';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      
      {/********** Page One *************/}
      <OneShellHome {...OneShellHomeObj} />
      <AppfeatureHome />

      
      <POSMobileBilling {...POSMobileBillingObj}/>
      <OnlineBusPage {...OnlineBusPageObj}/>
      <SuitForAll />
      <BillingFeatures />
      <AnnualPlans />
      <PartnerSay />
      <OurPartners />
      <Footer />

     
    </>
  )
}

export default Home
