import React from 'react';
import {
  Nav, NavbarContainer, NavLogo, MobileIcon,
  NavMenu, NavItem, NavLinks, Img, NavBtnLink
} from './NavbarElements';
import { FaBars } from 'react-icons/fa';
import { useState } from 'react';
import { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = ({ toggle }) => {
  const location = useLocation();
  const routeName = location.pathname;
  const navigate = useNavigate();

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  const handleNavigate = () => {
    navigate('/');
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer id='Home'>
          {routeName != "/deleteRequest" ? (<NavLogo to='Home' onClick={toggleHome}>
            <Img src={require('../../images/OneShell_Logo.png')} />
            OneShell</NavLogo>) : (
            <NavLogo to='Home' onClick={handleNavigate}>
              <Img src={require('../../images/OneShell_Logo.png')} />
              OneShell</NavLogo>
          )}
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          {routeName != "/deleteRequest" && <NavMenu>
            <NavItem>
              <NavLinks to='Home' onClick={toggleHome}
                style={{ color: '#ef5350' }}
              >Home</NavLinks>
            </NavItem>
            <NavItem>
              <NavBtnLink target='_blank' to={"//oneshell.in/"} >Online</NavBtnLink>
            </NavItem>
            <NavItem>
              <NavBtnLink target='_blank' to={"//pos.oneshell.in/#/faq"} >FAQ</NavBtnLink>
            </NavItem>
            <NavItem>
              <NavBtnLink target='_blank' to={"//pos.oneshell.in/#/login"} >POS Login</NavBtnLink>
            </NavItem>
          </NavMenu>}
        </NavbarContainer>
      </Nav>
    </>


  )
}

export default Navbar
