import React from 'react';

import Slider2 from '../Slider2';
import { OurPartnersData } from './OurPartnersData';
import { InfoContainer, InfoWrapper, InfoRow, 
    Heading, HeadingWrap } from './OurPartnersElements';

const OurPartners = () => {
  return (
    <>
        <HeadingWrap>
            <Heading>Our Clients</Heading>
        </HeadingWrap>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow>
                   <Slider2  slides={OurPartnersData} />
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
        
    </>
  )
}

export default OurPartners