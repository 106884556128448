import styled from 'styled-components';


export const InfoContainer = styled.div`
    background: #F3F3F2;
  
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    
    z-index: 1;
    height: 250px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 0px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 500px;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    height: 250px;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    justify-content: center;

    grid-template-areas: ${`'col1 col2 col3'` };
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${`'col1' 'col2' 'col3'`};
        
    }
`
export const InfoRow2 = styled.div`
    display: grid;
    height: 250px;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;

    grid-template-areas: ${`'col4 col5 col6'` };
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${`'col4' 'col5' 'col6'`};
    }
`;

export const HeadingWrap = styled.div`
    height: 75px;
    text-align: center;
    margin-top: 150px;
    background: #F3F3F2;
    @media screen and (max-width: 768px) {
        margin-top: 275px;
    }
`

export const Heading = styled.h1`
    color: #000;
    padding-right: 0px;
    padding-top: 30px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 550;
   
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const ImgWrapS = styled.div`
    max-width: 250px;
    height: 70%;
    /* text-align: center; */
    width: 225px;
`;

export const ImgS = styled.img`
    width: 100%;
    height: auto;
    /* height: 70%; */
    /* margin: 0 0 0px 0;
    padding-right: 0; */
    border: 2px solid #F3F3F2;
    border-radius: 10px 10px 0 0;
    margin-bottom: -10px;
`;

export const SubtitleS = styled.div`
    width: 100%;  
    font-size: 18px;
    color: #000;
    font-weight: 500;
    background: #fff;
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
`;

export const Column1 = styled.div`
    margin-top: 0px;
    padding: 0 50px;
    grid-area: col1;

    @media screen and (max-width: 768px) {
        margin-top: -75px;
    }
`;

export const Column2 = styled.div`
    padding: 0 50px;
    grid-area: col2;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }
`;

export const Column3 = styled.div`
    
    padding: 0 50px;
    grid-area: col3;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }
`;

export const Column4 = styled.div`
    
    padding: 0 50px;
    grid-area: col4;

    @media screen and (max-width: 768px) {
        margin-top: -125px;
    }
`;

export const Column5 = styled.div`
    padding: 0 50px;
    grid-area: col5;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }
`;

export const Column6 = styled.div`
    
    padding: 0 50px;
    grid-area: col6;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }
`;

/* export const Column4 = styled.div`
    margin-bottom: 10px;
    padding: 0 10px;
    grid-area: col4;
`; */