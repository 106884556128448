import React from 'react';
import { InfoContainer, InfoWrapper, InfoRow, SubtitleS, 
    CardWrap, Column1, Column2, Column3, Heading, HeadingWrap, Subtitle, PlusGst, AmountGstWrap  } from './AnnualPlansElements';

const AnnualPlans = () => {
  return (
    <>
        <HeadingWrap>
            <Heading>Our Annual Plans to suit every Business</Heading>
        </HeadingWrap>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow>
                    <Column1>
                        <CardWrap>
                            <Subtitle>Starter</Subtitle>
                            <br />
                            <AmountGstWrap>
                                <SubtitleS>₹7999 </SubtitleS>
                                <PlusGst>+ GST</PlusGst>
                            </AmountGstWrap>
                        </CardWrap>
                    </Column1>
                    <Column2>
                        <CardWrap>
                            <Subtitle>Essential</Subtitle>
                            <br />
                            <AmountGstWrap>
                                <SubtitleS>₹14999 </SubtitleS>
                                <PlusGst>+ GST</PlusGst>
                            </AmountGstWrap>
                        </CardWrap> 
                    </Column2>
                    <Column3>
                        <CardWrap>
                            <Subtitle>Custom</Subtitle>
                            <br />
                            <SubtitleS style={{textAlign:'left'}}>Contact Sales</SubtitleS> 
                        </CardWrap> 
                    </Column3>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
      
    </>
  )
}

export default AnnualPlans