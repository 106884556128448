import React from 'react';
import { InfoContainer, InfoWrapper, InfoRow, SubtitleS, 
ImgS, ImgWrapS, Column1, Column2, Column3, Heading, HeadingWrap, InfoRow2, Column5, Column6, Column4  } from './SuitForAllElements';

const SuitForAll = () => {
  return (
    <>
        <HeadingWrap>
            <Heading>We suit one and all</Heading>
        </HeadingWrap>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow>
                    <Column1>
                        <ImgWrapS>
                            <ImgS src={require('../../images/jewellery.png')}/>
                            <SubtitleS>Jewellery</SubtitleS> 
                        </ImgWrapS>
                    </Column1>
                    <Column2>
                        <ImgWrapS>
                            <ImgS src={require('../../images/wholesaleNdistributors.png')}/>
                            <SubtitleS>Wholesale {'&'} Distributors</SubtitleS>
                        </ImgWrapS> 
                    </Column2>
                    <Column3>
                        <ImgWrapS>
                            <ImgS src={require('../../images/bakery.png')}/>
                            <SubtitleS>Bakery {'&'} Fast foods</SubtitleS>
                            
                        </ImgWrapS> 
                    </Column3>
                </InfoRow>
                
            </InfoWrapper>
            
        </InfoContainer>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow2>
                    <Column4>
                        <ImgWrapS>
                            {/* <ImgS src={require('../../images/grocery.png')}/>
                            <SubtitleS>Grocery</SubtitleS>  */}
                            <ImgS src={require('../../images/restaurant.png')}/>
                            <SubtitleS>Restaurants</SubtitleS>
                    
                        </ImgWrapS>
                    </Column4>
                    <Column5>
                        <ImgWrapS>
                            <ImgS src={require('../../images/mandi.png')}/>
                            <SubtitleS>Mandi</SubtitleS>
                        </ImgWrapS> 
                    </Column5>
                    <Column6>
                        <ImgWrapS>
                            <ImgS src={require('../../images/virtual.png')}/>
                            <SubtitleS>General Stores</SubtitleS>
                        </ImgWrapS> 
                    </Column6>
                    
                </InfoRow2>
            </InfoWrapper>
        </InfoContainer>
      
    </>
  )
}

export default SuitForAll