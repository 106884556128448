import styled from 'styled-components';


export const InfoContainer = styled.div`
    color: #fff;
    
    /* background: ${({ lightBg }) => 
    (lightBg ? '#f9f9f9' : '#010606')}; */

    @media screen and (max-width: 768px) {
        padding: 10px 0;
    }
`;

export const InfoWrapper = styled.div`
    
    z-index: 1;
    height: 200px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 0px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 0px;
    }
`;

export const InfoRow = styled.div`
    
    height: 200px;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    /* background: #F1F1F1; */
    /* grid-template-areas: ${`'col1 col2 col3'` };
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${`'col1' 'col2' 'col3'`};
    } */
    
`;

export const HeadingWrap = styled.div`
    height: 180px;
    text-align: center;
    margin-top: 30px;
    
    @media screen and (max-width: 768px) {
        margin-top: 75px;
    }
`

export const Heading = styled.h1`
    color: #000;
    padding-right: 0px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 550;
   
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;
