import React from 'react';
import { InfoContainer, InfoWrapper, InfoRow, 
    Column2, Column1, TextWrapper, Heading, Subtitle, Img, ImgWrap } from './POSMobileBillingElements';
import {IoLogoGooglePlaystore } from "react-icons/io5";
import {BsGlobe } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const POSMobileBilling = ({lightBg, id, imgStart, topLine, lightText, headLine, darkText, description, buttonLabel, img, alt, subtitle}) => {
  
    
    return (
    <>
        <InfoContainer>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <Heading>{headLine}</Heading>
                            <Subtitle>{subtitle}</Subtitle>
                            <Subtitle>Available on Windows, Android {'&'} Web</Subtitle>
                            <Link target={'_blank'} to={"//play.google.com/store/apps/details?id=com.oneshellpartner"}>
                                <IoLogoGooglePlaystore style={{width:'25px', height:'25px', color:'#ef5350'}} />
                            </Link>
                            ' '
                            <Link target={'_blank'} to={"//pos.oneshell.in/#/login"}>
                                <BsGlobe style={{width:'25px', height:'25px', color:'#ef5350'}}/>  
                            </Link>
                        </TextWrapper>
                    </Column1>
                    
                    
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt} />
                        </ImgWrap>
                    </Column2>
                </InfoRow>
                
            </InfoWrapper>
        </InfoContainer>
            
      
    </>
  )
}

export default POSMobileBilling