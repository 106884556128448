import React from 'react'
import { CloseIcon, SidebarContainer, Icon, 
    SidebarWrapper, SidebarMenu, SidebarLink , SidebarRoute
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}> 
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to='Home' onClick={toggle}>
                    Home
                </SidebarLink>
                <SidebarRoute target='_blank' to={"//oneshell.in/"}>
                    Online
                </SidebarRoute>
                <SidebarRoute target='_blank' to={"//pos.oneshell.in/#/faq"}>
                    Faq
                </SidebarRoute>
                <SidebarRoute target='_blank' to={"//pos.oneshell.in/#/login"}>
                    POS Login
                </SidebarRoute> 
            </SidebarMenu>
            
        </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;